.mapSection {
  position: relative;
  font-family: Gordita, sans-serif;

  iframe {
    width: 100%;
    height: 832px;
  }

  &__contactsContainer {
    width: 345px;
    padding: 49.5px 37px;

    position: absolute;
    top: 130px;
    right: 150px;
    z-index: 10;

    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 13.5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    h3 {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px;
      text-transform: uppercase;
      color: #333333;
      margin-top: 0;
      margin-bottom: 17px;
    }

    p, a {
      font-size: 18px;
      line-height: 26px;
      color: #999999;
      margin: 0;
      text-decoration: none;
    }

    a {
      display: block;
    }
  }

  &__contactsBlock {
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1410px) {
  .mapSection {
    iframe {
      width: calc(100% - 345px);
      height: 600px;
    }

    &__contactsContainer {
      top: 0;
      right: 0;
      height: 100%;

      box-shadow: none;
      border-radius: 0;
    }
  }
}

@media (max-width: 1240px) {
  .mapSection {
    iframe {
      width: calc(100% - 280px);
      height: 450px;
    }

    &__contactsContainer {
      width: 280px;
      padding: 35px 20px;
    }
  }
}

@media (max-width: 950px) {
  .mapSection {
    iframe {
      width: calc(100% - 230px);
      height: 450px;
    }

    &__contactsContainer {
      width: 230px;
      padding: 35px 20px;

      h3 {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 20px;
      }

      p, a {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 750px) {
  .mapSection {
    display: flex;
    flex-direction: column;

    iframe {
      width: 100%;
      height: 300px;
    }

    &__contactsContainer {
      position: static;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      width: 100%;
    }

    &__contactsBlock {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 600px) {
  .mapSection {
    display: flex;
    flex-direction: column;

    &__contactsContainer {
      position: static;
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      width: 100%;
    }

    &__contactsBlock {
      margin-bottom: 30px;
    }
  }
}