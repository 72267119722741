.servicesSection {
  position: relative;
  padding: 100px 200px 50px 60px;
  display: flex;
  justify-content: space-between;

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 45px 45px;
    transform: rotate(-90deg);
    margin: 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 232px;
    left: 110px;
    z-index: 10;
  }

  &__descriptionContainer {
    width: calc(592 / 1905 * (100vw - 15px));
    margin: 0 1.5vw;
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0;
    }

    p {
      margin-top: 190px;
      font-weight: 500;
      font-size: 20px;
      line-height: 35px;
      text-align: justify;
    }
  }

  &__previewContainer {
    margin-top: 50px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(20 / 1905 * (100vw - 15px));
  ;

    &>div {
      position: relative;
      width: calc(435 / 1905 * (100vw - 15px));
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;

      &:hover div {
        opacity: 0.4;
        transition: opacity 0.5s ease;
      }

      p {
        font-family: Gordita, sans-serif;
        font-weight: bold;
        font-size: 36px;
        line-height: 51px;
        text-align: center;
        letter-spacing: -1px;
        color: #FFFFFF;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 5;
      }
    }
  }

  &__firstPreview {
    background: linear-gradient(rgba(#435686, 0.3), rgba(#435686, 0.3)), url('assets/ServicesPreview1.png');
    background-size: cover;
  }
  &__secondPreview {
    background: linear-gradient(rgba(#000, 0.3), rgba(#000, 0.3)), url('assets/ServicesPreview2.png');
    background-size: cover;
  }
  &__thirdPreview {
    background: linear-gradient(rgba(#B05500, 0.3), rgba(#B05500, 0.3)), url('assets/ServicesPreview3.png');
    background-size: cover;
  }
  &__fourthPreview {
    background: linear-gradient(rgba(#315602, 0.3), rgba(#315602, 0.3)), url('assets/ServicesPreview4.png');
    background-size: cover;
  }

  &__hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    opacity: 0;
    transition: opacity 0.8s ease;
  }

  &__servicesList {
    display: none;
    color: #4C57A1;
    font-family: Gordita, sans-serif;
  }
}

@media (max-width: 1480px) {
  .servicesSection {
    padding-top: 150px;
    padding-bottom: 50px;
    width: calc(100vw - 340px);
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 30px;

    &__sectionNumber {
      top: 50px;
    }

    &__descriptionContainer {
      width: 293px;
      flex-direction: column;

      p {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
      }
    }

    &__previewContainer {
      width: auto;
      box-sizing: border-box;
      margin: 0;
      padding-right: 50px;
      grid-gap: 20px;
      justify-content: end;

      &>div {
        p {
          font-size: 30px;
          padding: 0 10px;
        }
      }
      
      div {
        width: auto;
        height: auto;
        overflow: hidden;
      }

      .firstHide {
        display: none;
      }
    }
  }
}

@media (max-width: 1430px) {
  .servicesSection {
    &__previewContainer {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 170px 170px;

      &>div {
        p {
          font-size: 30px;
        }
      }
    }
  }
}


@media (max-width: 1375px) {
  .servicesSection {
    padding-bottom: 150px;
  }
}

@media (max-width: 1280px) {
  .servicesSection {
    grid-template-columns: 20px auto;
    grid-template-areas:
            "shortName description"
            ". previewContainer";

    &__shortName {
      grid-area: shortName;
    }

    &__descriptionContainer {
      width: calc(100% - 50px);
      grid-area: description;
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    &__previewContainer {
      margin: 0 1.5vw;
      padding-right: 20px;
      grid-area: previewContainer;

      &>div {
        p {
          font-size: 26px;
        }
      }
    }
  }
}

@media (max-width: 870px) {
  .servicesSection {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 110px;

    &__shortName {
      display: none;
    }

    &__sectionNumber {
      display: none;
    }

    &__descriptionContainer {
      margin: 0;
      width: calc(100% - 60px);

      p {
        margin: 20px 0;
      }
    }

    &__previewContainer {
      width: calc(100% - 60px);
      margin: 0;
      padding-right: 0;

      &>div {
        p {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .servicesSection {
    padding: 50px 25vw 50px 30px;

    &__descriptionContainer {
      width: calc(75vw - 75px);
    }

    &__previewContainer {
      width: calc(75vw - 75px);
    }
  }
}

@media (max-width: 755px) {
  .servicesSection {
    width: 100%;
    box-sizing: border-box;
    padding-top: 34px;

    &__descriptionContainer {
      margin-top: 0;

      h2 {
        margin-top: 0;
        font-size: 50px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }
    }

    &__previewContainer {
      grid-template-columns: 1fr;
      grid-template-rows: 170px 170px 170px 170px;
    }
  }
}

@media (max-width: 610px) {
  .servicesSection {
    grid-template-columns: 1fr;
    padding: 0 5vw 30px 5vw;

    &__descriptionContainer {
      width: 100%;
      margin-top: 40px;

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }
    }

    &__previewContainer {
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 170px 170px;

      div {
        p {
          font-size: 30px;
          line-height: 44px;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .servicesSection {
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }

    &__previewContainer {
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 170px 170px 170px 170px;
    }
  }
}