.projectsSection {
  padding-bottom: 80px;

  &__titleContainer {
    padding: 100px 212px 100px 60px;
    position: relative;
    display: flex;
  }

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 60px 60px;
    transform: rotate(-90deg);
    margin: 70px 0 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 137px;
    left: calc(50% - 389px / 2);
  }

  &__descriptionContainer {
    margin-top: 100px;
    width: 800px;
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0 50px 0;
    }
  }

  &__sliderContainer {
    width: calc(100% - 385px);
    margin-left: 60px;
    height: auto;
    display: none;
  }

  &__projectSlide {
    img {
      width: 100%;
    }
  }

  &__slider {
    width: calc(100vw - 135px);
    z-index: 13;
    padding-right: 15px;
    box-sizing: border-box;
  }

  &__sliderCard {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 35px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 8.85612px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center;

    div {
      z-index: 10;
      width: calc(100% - 40px);
      height: auto;
      overflow: hidden;
      background-size: cover;
      background-position: center center;

      img {
        width: 100%;
      }
    }
  }

  &__cardsContainer {
    display: flex;
    flex-direction: column;
    position: relative;

    a {
      position: absolute;
      right: 100px;
      bottom: -100px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
      font-size: 26px;
      line-height: 76px;
      letter-spacing: -2px;
      color: #4C57A1;
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        margin-bottom: 3px;
        margin-left: 15px;
      }
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    padding: 0 61px;
    width: 100%;
    box-sizing: border-box;

    div {
      z-index: 10;
      width: calc((100% - 40px) / 3);

      img {
        width: 100%;
      }
    }
  }

  &__project {
    object-fit: cover
  }

  .objectFitContain {
    object-fit: contain
  }
}

@media (max-width: 1375px) {
  .projectsSection {
    padding-top: 60px;

    &__sectionNumber {
      top: 100px;
      left: 110px;
    }

    &__titleContainer {
      padding-top: 100px;
    }
  }
}

@media (max-width: 1100px) {
  .projectsSection {
    &__titleContainer {
      padding-bottom: 50px;
    }

    &__sliderContainer {
      display: block;
    }

    &__cards {
      display: none;
    }

    &__cardsContainer {
      a {
        font-size: 22px;
        right: 50px;
        bottom: -120px;
      }
    }
  }
}

@media (max-width: 990px) {
  .projectsSection {
    &__sliderContainer {
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 870px) {
  .projectsSection {
    padding-top: 100px;
    &__sectionNumber {
      display: none;
    }

    &__shortName {
      display: none;
    }

    &__descriptionContainer {
      margin: 0;

      h2 {
        margin: 0;
      }
    }

    &__titleContainer {
      padding-right: 60px;
    }
  }
}

@media (max-width: 820px) {
  .projectsSection {
    &__titleContainer {
      padding: 0 30px 50px 30px;
    }

    &__sliderContainer {
      width: calc(75vw - 75px);
      margin-left: 30px;
    }

    &__cardsContainer {
      a {
        font-size: 22px;
        right: 30px;
        bottom: -120px;
      }
    }
  }
}

@media (max-width: 755px) {
  .projectsSection {
    &__titleContainer {
      padding-bottom: 20px;
    }

    &__descriptionContainer {
      h2 {
        font-size: 50px;
      }
    }
  }
}

@media (max-width: 610px) {
  .projectsSection {
    padding: 80px 4% 30px 4%;

    &__descriptionContainer {
      h2 {
        font-size: 50px;
      }
    }

    &__titleContainer {
      padding-bottom: 10px;
      padding-left: 0;
    }

    &__sliderContainer {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media (max-width: 410px) {
  .projectsSection {
    padding-top: 70px;
    padding-bottom: 0;
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }

    &__titleContainer {
      padding-left: 0;
    }

    &__cardsContainer {
      a {
        font-size: 20px;
        bottom: -100px;

        img {
          width: 11px;
          height: 19px;
        }
      }
    }
  }
}