.formSection {
  padding-top: 70px;
  padding-bottom: 70px;
  background: url('assets/FormBG.png');
  position: relative;

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 75px 75px;
    transform: rotate(-90deg);
    margin: 70px 0 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 290px;
    left: 90px;
    z-index: 10;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-family: Gordita, sans-serif;
      font-weight: 500;
      font-size: 64px;
      line-height: 91px;
      color: #4C57A1;
      margin-top: 0;
      margin-bottom: 15px;
    }

    p {
      font-family: Gordita, sans-serif;
      font-size: 26px;
      line-height: 37px;
      color: #4C57A1;
      margin-top: 0;
      margin-bottom: 74px;
    }

    form {
      display: flex;
      flex-direction: column;
      z-index: 13;

      label {
        margin-top: 0;
        margin-bottom: 50px;
        width: 880px;
        display: flex;
        flex-direction: column;

        font-family: Gordita, sans-serif;
        font-size: 20px;
        line-height: 28px;
        color: #4C57A1;

        input, textarea {
          padding: 15px;
          border: 1px solid #BEB8FF;
          box-sizing: border-box;
          border-radius: 5px;
          background: #fbfbff;

          font-family: Gordita,sans-serif;
          font-size: 16px;
          line-height: 23px;
          color: #596AB8;
        }
      }

      div {
        display: flex;
        justify-content: center;

        button {
          border: none;
          margin: 0 16px;
          width: 320px;
          padding: 13px 0 10px;
          background: #36A9E1;
          border-radius: 10px;
          font-family: Gordita, sans-serif;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .formSection {
    &__content {
      width: calc(100vw - 135px);
      margin-left: 60px;

      p {
        margin-bottom: 35px;
        text-align: center;
      }

      form {
        label {
          margin-bottom: 35px;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .formSection {

    h3 {
      line-height: 64px;
    }

    &__content {
      width: calc(100% - 60px);
      margin-left: 30px;

      form {
        label {
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .formSection {
    h3 {
      font-size: 55px;
    }

    &__content {
      form {
        label {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .formSection {
    h3 {
      font-size: 50px;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 15px;
      font-weight: normal;
    }

    &__content {
      form {
        label {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 20px;

          input, textarea {
            padding: 10px 10px 7px;
            font-size: 14px;
            line-height: 16px;
          }
        }

        div {
          button {
            font-size: calc(10px + 3vw);
            padding: 5px 30px;
            width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 510px) {
  .formSection {
    h3 {
      font-size: 35px;
      line-height: 35px;
    }
  }
}

@media (max-width: 410px) {
  .formSection {
    h3 {
      font-size: 25px;
      line-height: 25px;
    }
  }
}
