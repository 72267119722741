.footer {
  font-family: Gordita, sans-serif;
  background: #1D1D1D;
  padding: 69px calc(309 / 1905 * (100vw - 15px));
;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -4px;

  &__companyName {
    font-weight: bold;
    font-size: 34.1252px;
    line-height: 35px;
    letter-spacing: -3.58896px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
  }

  &__companyScope {
    font-family: DM Sans, sans-serif;
    font-size: 16.5px;
    line-height: 18px;
    color: #E4E4E4;
    margin: 0;
  }

  &__nav {
    width: 20vw;
    display: flex;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        font-weight: bold;
        margin-bottom: 14px;

        a {
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  &__navSM {
    display: none;
    width: calc(60vw - 200px);
    margin-top: 50px;
    margin-bottom: 50px;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 14px;

        a {
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  &__contactsInfo {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;

    a {
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      text-decoration: none;
    }

    p {
      margin-top: 0;
      margin-bottom: 15px;
      line-height: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    div {
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: 1280px) {
  .footer {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

@media (max-width: 1180px) {
  .footer {
    flex-direction: column;

    &__company {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__nav {
      display: none;
    }

    &__navSM {
      display: flex;
      justify-content: space-between;
    }

    &__contactsInfo {
      text-align: center;
    }
  }
}

@media (max-width: 860px) {
  .footer {
    &__navSM {
      width: 320px;
    }
  }
}

@media (max-width: 380px) {
  .footer {
    &__navSM {
      width: auto;
      flex-direction: column;
      text-align: center;
    }
  }
}
