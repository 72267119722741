.businessModelsSection {
  padding-bottom: 20px;
  position: relative;

  &__titleContainer {
    padding: 100px 200px 100px 60px;
    position: relative;
    display: flex;
  }

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 80px 80px;
    transform: rotate(-90deg);
    margin: 70px 0 0;
    display: flex;
    width: 26px;
  }

  &__sliderContainer {
    width: calc(100% - 265px);
    display: none;
  }

  &__slider {
    width: calc(100% - 120px);
    z-index: 13;
    box-sizing: border-box;
  }

  &__sliderCard {
    position: relative;
    height: 100%;
    padding: 35px;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center;

    img {
      margin-bottom: 8px;
      width: 11px;
      height: 19px;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      font-family: Gordita, sans-serif;
      font-weight: bold;
      font-size: 40.0949px;
      line-height: 76px;
      letter-spacing: -3.00712px
    }

    p {
      margin-top: 0;
      //margin-bottom: 50px;
      font-family: Gordita, sans-serif;
      font-size: 20px;
      text-align: center;
      line-height: 30px;
    }

    a {
      position: absolute;
      right: 55px;
      bottom: 34px;
      font-family: Gordita, sans-serif;
      font-size: 20.0474px;
      line-height: 37px;
      letter-spacing: -0.848892px;
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        margin-bottom: 0;
        margin-left: 14px;
      }
    }
  }

  &__sectionNumber {
    position: absolute;
    left: calc(50% - 389px / 2);
    z-index: 10;
  }

  &__descriptionContainer {
    margin-top: 100px;
    width: 800px;
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0 50px 0;
    }
  }

  &__cardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>div>div:hover {
      box-shadow: none;
      transition: all 0.5s;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 403px 403px 403px;
    grid-gap: 50px;

    div {
      position: relative;
      width: 403px;
      height: 530px;
      padding: 44px 54px 34px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0 0 8.85612px rgba(0, 0, 0, 0.2);
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;

      img {
        margin-bottom: 8px;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-family: Gordita, sans-serif;
        font-weight: bold;
        font-size: 40.0949px;
        line-height: 76px;
        letter-spacing: -3.00712px
      }

      p {
        margin: 0;
        font-family: Gordita, sans-serif;
        font-size: 20px;
        text-align: center;
        line-height: 30px;
      }

      a {
        position: absolute;
        right: 55px;
        bottom: 34px;
        font-family: Gordita, sans-serif;
        font-size: 20.0474px;
        line-height: 37px;
        letter-spacing: -0.848892px;
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
          margin-bottom: 0;
          margin-left: 14px;
        }
      }
    }
  }

  &__card1 {
    color: #9100D6;

    a {
      color: #9100D6;
    }
  }

  &__card2 {
    color: #235BEB;

    a {
      color: #235BEB;
    }
  }

  &__card3 {
    color: #3DAE2B;

    a {
      color: #3DAE2B;
    }
  }

  &__estimateButton {
    background: #36A9E1;
    border-radius: 10px;

    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26.087px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;

    padding: 15px 0;
    width: 320px;
    margin-top: 50px;
    cursor: pointer;
    box-shadow: 0 0 8.85612px rgba(0, 0, 0, 0.4);
    transition: all 0.3s;

    &:hover {
      box-shadow: none;
      transition: all 0.3s;
    }
  }
}

@media (max-width: 1375px) {
  .businessModelsSection {
    &__sectionNumber {
      top: -10px;
      left: 110px;
    }

    &__sliderContainer {
      display: flex;
      justify-content: center;
    }

    &__titleContainer {
      padding-top: 0;
    }

    &__cardsContainer {
      display: none;
    }
  }
}

@media (max-width: 870px) {
  .businessModelsSection {
    &__shortName {
      display: none;
    }

    &__sectionNumber {
      display: none;
    }

    &__descriptionContainer {
      margin-top: 0;
      h2 {
        width: 100%;
        margin: 0;
      }
    }

    &__titleContainer {
      padding-top: 0;
      padding-bottom: 40px;
    }

    &__sliderContainer {
      display: flex;
      justify-content: center;
    }

    &__cardsContainer {
      display: none;
    }
  }
}

@media (max-width: 820px) {
  .businessModelsSection {
    &__descriptionContainer {
      margin-top: 0;
      h2 {
        width: 100%;
        margin-top: 50px;
      }
    }

    &__titleContainer {
      padding-left: 30px;
      padding-right: 30px;
    }

    &__sliderContainer {
      width: calc(75vw - 15px);
    }

    &__slider {
      width: calc(100% - 60px);
    }

    &__cardsContainer {
      display: none;
    }
  }
}

@media (max-width: 755px) {
  .businessModelsSection {
    &__titleContainer {
      padding-bottom: 20px;
    }

    &__descriptionContainer {
      h2 {
        font-size: 50px;
      }
    }

    &__slider {
      h3 {
        font-size: 32px;
        line-height: 50px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 610px) {
  .businessModelsSection {
    grid-template-columns: 1fr;
    padding: 0 5% 30px 5%;
    box-sizing: border-box;

    &__descriptionContainer {
      width: 100%;

      h2 {
        font-size: 50px;
      }
    }

    &__titleContainer {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 10px;
    }

    &__sliderContainer {
      width: 90%;
      margin-left: 5%;
    }

    &__slider {
      width: 90vw;
      p {
        margin-top: 10px;
      }
    }

    &__sliderCard {
      a {
        right: 30px;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 410px) {
  .businessModelsSection {
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }

    &__slider {
      width: 90vw;
    }

    &__sliderCard {
      padding: 20px;

      h3 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 35px;
        line-height: 35px;
      }
    }
  }
}

@media (max-width: 330px) {
  .businessModelsSection {
    &__descriptionContainer {
      h2 {
        line-height: 40px;
        text-align: center;
      }
    }

    &__sliderCard {
      h3 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 30px;
      }

      p {
        margin-bottom: 65px;
      }
    }
  }
}