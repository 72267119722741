.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #1D1D1D;

  &__container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: #1D1D1D;
    padding: 30px 212px 30px 60px;
    box-sizing: border-box;
  }

  .hide {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100vw;
    height: 0;

    background-color: #1D1D1D;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.3s height;
  }

  .show {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #1D1D1D;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.5s height;
  }

  .openedMenu {
    display: none;
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: Gordita, sans-serif;

    &__item {
      color: #fff;
      font-size: 5vh;
      margin-bottom: 5vh;
      text-align: center;
      font-weight: bold;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    color: #fff;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 14px;

    li {
      line-height: 35px;
      padding: 0 10px;
      font-weight: 500;
      font-size: 20px;

      a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .burgerMenu {
    display: none;
    margin-top: 2.5px;
    padding: 0;
    background: transparent;
    border:none;
  }
}

@media (max-width: 1400px) {
  .header {
    &__container {
      padding-right: 60px;
    }

    ul {
      display: none;
    }

    .burgerMenu {
      margin-bottom: -5px;
      display: block;
      outline: none;
    }

    .show {
      .openedMenu {
        display: block;
      }
    }
  }
}

@media (max-width: 1235px) {
  .header {
    &__container {
      padding: 20px 60px;
    }
  }
}

@media (max-width: 1000px) {
  .header {

  }
}

@media (max-width: 550px) {
  .header {
    .show {
      .openedMenu {
        &__item {
          margin-bottom: 4vh;
        }
      }
    }

    &__container {
      padding: 15px 30px 20px;
    }

    .burgerMenu {
      font-size: 9px;
    }
  }
}

