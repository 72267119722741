.logo {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  cursor: pointer;
}
.logo_img_container {
  height: 35px;
}
.title{
  margin-left: calc(5px + 0.45vw);
  display: block;
  align-items: baseline;
}
.title_text {
  font-family: Gordita, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 20px;
  letter-spacing: -3px;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 200px;
}

@media (max-width: 550px) {
  .logo {
    margin-bottom: 0;
  }
  .logo_img_container{
    height: 28px;
  }
  .title_text {
    font-size: 28px;
    line-height: 17px;
  }
}

@media (max-width: 400px) {
  .logo_img_container{
    height: 24px;
  }
  .title_text {
    font-size: 24px;
    line-height: 13px;
    width: 130px;
  }
}

