.technologiesSection {
  position: relative;
  padding: 100px 335px 50px 60px;
  display: flex;
  justify-content: space-between;

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 95px 95px;
    transform: rotate(-90deg);
    margin: 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 66px;
    left: 95px;
    z-index: 10;
  }

  &__descriptionContainer {
    margin-top: 100px;
    width: calc(100vw - 395px - (478 / 1905 * (100vw - 15px)) - 20vw);
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 35px;
      text-align: justify;
    }

    a {
      margin-top: 70px;
      display: flex;
      justify-content: flex-end;
      text-decoration: none;
      font-family: Gordita, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #4C57A1;

      img {
        margin-left: 16px;
      }
    }
  }

  &__previewContainer {
    width: calc(478 / 1905 * (100vw - 15px));
    margin: 100px 5vw 0;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2vw;

    img {
      margin: 0 auto;
    }
  }
}

@media (max-width: 1480px) {
  .technologiesSection {
    padding-right: 310px;
    &__descriptionContainer {
      width: calc(100vw - 470px);
    }

    &__previewContainer {

        display: none;
    }
  }
}

@media (max-width: 1235px) {
  .technologiesSection {
    &__previewContainer {
      width: calc(150 / 1905 * (100vw - 15px));
      margin: 100px 5vw 0 -0.5vw;
      grid-template-rows: 180px 180px;
      grid-template-columns: 1fr;

      .secondHide {
        display: none;
      }
    }
  }
}

@media (max-width: 1090px) {
  .technologiesSection {
    &__descriptionContainer {
      width: calc(100vw - 455px);
    }

    &__previewContainer {
      display: none;
    }
  }
}

@media (max-width: 870px) {
  .technologiesSection {
    padding-top: 0;
    padding-bottom: 80px;
    &__shortName {
      display: none;
    }

    &__sectionNumber {
      display: none;
    }

    &__descriptionContainer {
      margin-top: 50px;
      width: calc(100vw - 400px);
      .singlePreview {
        height: 300px;
      }
    }
  }
}

@media (max-width: 820px) {
  .technologiesSection {
    padding: 0 25vw 50px 30px;

    &__descriptionContainer {
      width: calc(75vw - 75px);
    }

    a {
      margin-top: 30px;
    }
  }
}

@media (max-width: 755px) {
  .technologiesSection {
    &__descriptionContainer {
      margin-top: 40px;

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }

      a {
        margin-top: 30px;
        justify-content: flex-start;
        text-transform: uppercase;
        font-weight: bold;

        img {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .technologiesSection {
    grid-template-columns: 1fr;
    padding: 0 5vw 14px 5vw;
    box-sizing: border-box;

    &__descriptionContainer {
      width: 100%;

      .singlePreview {
        height: 250px;
        box-shadow: none;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 410px) {
  .technologiesSection {
    &__descriptionContainer {
      .singlePreview {
        height: auto;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 410px) {
  .technologiesSection {
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 355px) {
  .technologiesSection {
    &__descriptionContainer {
      a {
        width: 180px;
        display: flex;
        align-items: center;

        img {
          width: 35px;
          height: 30px;
        }
      }
    }
  }
}
