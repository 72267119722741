.aboutSection {
  position: relative;
  padding: 100px 212px 50px 60px;
  display: flex;
  justify-content: space-between;

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 85px 85px;
    transform: rotate(-90deg);
    margin: 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 87px;
    left: 110px;
  }

  &__descriptionContainer {
    z-index: 10;
    margin-top: 100px;
    width: calc(800 / 1905 * (100vw - 15px));
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0;
    }

    .singlePreview {
      display: none;
      width: 100%;
      overflow: hidden;
      box-shadow: 0.5px 0.5px 20px rgba(0, 0, 0, 0.3);

      img {
        width: 100%;
      }
    }

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: justify;
    }
  }

  &__previewContainer {
    width: calc(596 / 1905 * (100vw - 15px));
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>img {
      width: 100%;
    }

    div {
      padding: 15px 5px;
      display: flex;
      justify-content: space-between;

      img {
        width: calc((100% - 60px) / 3);
        box-shadow: 0 4.08347px 4.08347px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@media (max-width: 1480px) {
  .aboutSection {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 5vw;

    &__descriptionContainer {
      width: calc(700 / 1905 * (100vw - 15px));
    }

    &__previewContainer {
      width: calc(700 / 1905 * (100vw - 15px));
    }
  }
}

@media (max-width: 1235px) {
  .aboutSection {
    grid-template-columns: auto 1fr;

    &__descriptionContainer {
      width: calc(100vw - 455px);

      .singlePreview {
        display: block;
        margin-top: 20px;
        margin-bottom: 30px;
        height: 290px;

        img {
          width: 110%;
          margin-left: -5%;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    &__previewContainer {
      display: none;
    }
  }
}

@media (max-width: 870px) {
  .aboutSection {
    grid-template-columns: 1fr;

    &__shortName {
      display: none;
    }

    &__sectionNumber {
      display: none;
    }

    &__descriptionContainer {
      margin-top: 0;
      width: calc(100vw - 400px);

      p {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 820px) {
  .aboutSection {
    padding: 100px 295px 50px 30px;

    &__descriptionContainer {
      width: calc(75vw - 75px);
    }
  }
}

@media (max-width: 755px) {
  .aboutSection {
    &__descriptionContainer {
      .singlePreview {
        height: auto;
        box-shadow: none;
        margin-bottom: 10px;
        margin-top: 0;
      }

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 610px) {
  .aboutSection {
    grid-template-columns: 1fr;
    padding: 0 5vw 30px 5vw;
    box-sizing: border-box;

    &__descriptionContainer {
      width: 100%;
      margin-top: 40px;

      .singlePreview {
        height: 250px;
        box-shadow: none;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 410px) {
  .aboutSection {
    &__descriptionContainer {
      .singlePreview {
        height: auto;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 410px) {
  .aboutSection {
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }
  }
}
