.banner{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(60, 79, 176, 0.5), rgba(60, 79, 176, 0.5)), url('assets/Banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  &__title {
    font-family: Gordita, sans-serif;
    font-weight: bold;
    font-size: 87.551px;
    line-height: 61px;
    letter-spacing: -5.17347px;
    text-shadow: 0 0 7.95918px rgba(0, 0, 0, 0.25);
    margin-top: 0;
    margin-bottom: 23px;
    text-align: center;
  }

  &__description {
    font-family: Gordita, sans-serif;
    font-size: 28.6531px;
    line-height: 41px;
    letter-spacing: -0.795918px;
    width: 680px;
    text-align: center;
    margin-bottom: 60px;
  }

  &__estimateButton {
    background: #36A9E1;
    border-radius: 10px;

    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26.087px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;

    padding: 15px 0;
    width: 320px;

    cursor: pointer;
  }

  &__scrollButton {
    position: absolute;
    bottom: 20px;
  }
}

@media (max-width: 1000px) {
  .banner {
    &__title {
      font-size: calc(20px + 6vw);
    }

    &__description {
      width: 470px;
      padding: 0 15px;
      margin-top: 0;
      margin-bottom: 35px;
    }
  }
}

@media (max-width: 550px) {
  .banner {
    &__title {
      font-size: calc(15px + 3.5vw);
      letter-spacing: -1.2px;
      margin-bottom: 0;
    }

    &__description {
      font-size: calc(8px + 3.2vw);
      line-height: 30px;
      width: 290px;
      margin-bottom: 15px;
    }

    &__estimateButton {
      font-size: 18px;
      padding: 5px 30px;
      width: auto;
    }

    &__scrollButton {
      display: none;
    }
  }
}
