@import "fonts/fonts.scss";

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Gilroy, Monaco, Consolas, 'Courier New', monospace;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: #596AB8;
}

.slick-dots {
  bottom: -50px;
}

@media (max-width: 410px) {
  .slick-dots {
    bottom: -30px;
  }
}
