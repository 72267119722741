.feedbackSection {
  padding-bottom: 30px;

  &__titleContainer {
    padding: 100px 212px 115px 60px;
    position: relative;
    display: flex;
  }

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 60px 60px;
    transform: rotate(-90deg);
    margin: 70px 0 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 60px;
    left: calc(50% - 389px / 2);
    z-index: 10;
  }

  &__descriptionContainer {
    margin-top: 100px;
    width: 800px;
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0 50px 0;
    }
  }

  &__feedbackContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-family: Gordita, sans-serif;
      font-size: 42px;
      line-height: 60px;
      color: #000000;
      margin: 0;
    }
  }

  &__feedbackContainer {
    a {
      img {
        width: 126px;
        height: auto;
        margin-bottom: 0;
        margin-left: 14px;
      }
    }
  }

  &__feedback {
    display: grid;
    grid-template-columns: 586px 586px;
    grid-gap: 80px 160px;
    margin-bottom: 30px;

    p {
      font-family: Gordita, sans-serif;
      font-size: 20px;
    }
  }

  &__feedbackStars {
    img, span {
      margin: 35px 17.5px;
    }
  }

  &__feedbackText {
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  &__feedbackAuthor {
    font-weight: 500;
    line-height: 28px;
    color: #000000;
    margin: 0;
  }
}

@media (max-width: 1490px) {
  .feedbackSection {
    &__feedbackContainer {
      width: calc(100vw - 135px);
      margin-left: 60px;
    }

    &__feedback {
      grid-template-columns: 1fr 1fr;
      grid-gap: 5vw 10vw;
    }

    &__feedbackStars {
      img:first-child {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 1375px) {
  .feedbackSection {
    padding-top: 20px;

    &__sectionNumber {
      top: 40px;
      left: 110px;
    }

    &__titleContainer {
      padding-top: 40px;
      padding-bottom: 100px;
    }
  }
}

@media (max-width: 1100px) {
  .feedbackSection {
    &__titleContainer {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 1000px) {
  .feedbackSection {
    &__feedbackStars {
      img {
        margin: 25px 10px;
      }
    }
  }
}

@media (max-width: 870px) {
  .feedbackSection {
    padding-top: 0;
    &__shortName {
      display: none;
    }

    &__sectionNumber {
      display: none;
    }

    &__descriptionContainer {
      margin-top: 50px;
      width: calc(100vw - 400px);

      h2 {
        margin: 0;
      }
    }

    &__titleContainer {
      padding-right: 60px;
    }
  }
}

@media (max-width: 820px) {
  .feedbackSection {
    padding-top: 30px;
    &__feedbackContainer {
      width: calc(100% - 60px);
      margin-left: 30px;
    }

    &__titleContainer {
      padding: 0 30px 40px 30px;
    }

    &__feedbackStars {
      img {
        width: 30px;
        margin: 15px 5px;
      }
    }
  }
}

@media (max-width: 755px) {
  .feedbackSection {
    padding-top: 30px;
    &__titleContainer {
      padding-bottom: 10px;
    }

    &__descriptionContainer {
      h2 {
        font-size: 50px;
      }
    }

    &__feedbackText {
      font-size: 16px!important;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__feedbackAuthor {
      font-size: 16px !important;
    }

    h3 {
      font-size: 30px;
    }
  }
}

@media (max-width: 610px) {
  .feedbackSection {
    padding-top: 50px;
    grid-template-columns: 1fr;

    &__titleContainer {
      padding: 0 5%;
    }

    &__feedbackContainer {
      width: 90%;
      margin-left: 5%;
    }

    &__descriptionContainer {
      width: 100%;
      margin-top: 40px;

      h2 {
        font-size: 50px;
      }
    }

    &__feedback {
      grid-template-columns: 1fr;
      grid-gap: 5vw;

      &>div:nth-child(4) {
        display: none;
      }
    }

    &__feedbackStars {
      img {
        width: 20px;
        margin: 5px 5px;
      }
    }
  }
}

@media (max-width: 410px) {
  .feedbackSection {
    &__titleContainer {
      padding-left: 5%;
    }

    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }

    &__feedback {
      &>div:nth-child(3) {
        display: none;
      }
    }

    h3 {
      font-size: 24px;
    }
  }
}

@media (max-width: 320px) {
  .feedbackSection {
    h3 {
      font-size: 20px;
    }
  }
}
