.presentationDownloadSection {
  padding-bottom: 170px;

  &__titleContainer {
    padding: 100px 212px 100px 60px;
    position: relative;
    display: flex;
  }

  &__shortName {
    font-family: Bebas Neue, sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #596AB8;
    transform-origin: 90px 90px;
    transform: rotate(-90deg);
    margin: 70px 0 0;
    display: flex;
    width: 26px;
  }

  &__sectionNumber {
    position: absolute;
    top: 300px;
    left: 110px;
    z-index: 10;
  }

  &__descriptionContainer {
    margin-top: 100px;
    color: #4C57A1;
    font-family: Gordita, sans-serif;

    h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 76px;
      letter-spacing: -3px;
      margin: 0 50px 0;
    }
  }

  &__presentationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-family: Gordita, sans-serif;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      color: #4C57A1;
      margin-top: 0;
      margin-bottom: 52px;
    }
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
  }

  &__downloadPdfButton {
    border: none;
    margin: 0 16px;
    width: 320px;
    padding: 13px 0 10px;
    background: #36A9E1;
    border-radius: 10px;
    font-family: Gordita, sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
}

@media (max-width: 1375px) {
  .presentationDownloadSection {
    padding-bottom: 120px;
    padding-top: 60px;

    &__sectionNumber {
      top: 40px;
      left: 110px;
    }

    &__titleContainer {
      padding-top: 40px;
    }

    &__presentationContainer {
      width: calc(100vw - 135px);
      margin-left: 60px;

      p {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1100px) {
  .presentationDownloadSection {
    padding-bottom: 100px;
    &__titleContainer {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 870px) {
  .presentationDownloadSection {
    padding-bottom: 90px;
    &__sectionNumber {
      display: none;
    }

    &__shortName {
      display: none;
    }

    &__descriptionContainer {
      margin: 0;

      h2 {
        margin: 0;
      }
    }

    &__titleContainer {
      padding-right: 60px;
    }
  }
}

@media (max-width: 820px) {
  .presentationDownloadSection {
    padding-bottom: 80px;
    &__titleContainer {
      padding: 0 30px 50px 30px;
    }
    &__presentationContainer {
      width: calc(100% - 60px);
      margin-left: 30px;
    }
  }
}

@media (max-width: 755px) {
  .presentationDownloadSection {
    &__descriptionContainer {
      margin-top: 40px;

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 610px) {
  .presentationDownloadSection {
    padding-bottom: 70px;
    &__descriptionContainer {
      h2 {
        font-size: 50px;
        line-height: 50px;
        text-align: center;
      }
    }

    &__titleContainer {
      padding-bottom: 10px;
    }

    &__presentationContainer {
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        font-weight: normal;
        margin-bottom: 20px;
      }

      img {
        display: none;
      }
    }

    &__downloadPdfButton {
      font-size: calc(10px + 3vw);
      padding: 5px 30px;
      width: auto;
    }
  }
}

@media (max-width: 410px) {
  .presentationDownloadSection {
    padding-bottom: 60px;
    &__descriptionContainer {
      h2 {
        font-size: 40px;
      }
    }
  }
}